import React from 'react';

import AlternativeLayout from '../components/layout/AlternativeLayout';
// import DataSafetyPDF from '../data/Datenschutzerklärung_JiM_Morrowmed.pdf';
import SiteMetadata from '../components/site-metadata';
import Contract from '../svg/Contract';

const UserAgreements = () => (
  <>
    <SiteMetadata pathname="user-agreements" />
    <AlternativeLayout>
      <section className="py-16">
        <div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-20 lg:px-8">
          <div className="text-center lg:text-left px-8">
            <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
              Nutzungs-bedingungen
            </h1>
            <p className="text-xl lg:text-xl mt-6 font-light">
              Welchen Umfang unser Angebot umfasst und welche Leistungen du damit von uns erwarten
              kannst, haben wir hier für dich zusammengefasst.
              <br />
            </p>
            <p className="flex flex-col text-xl lg:text-xl mt-6 font-light space-y-8">
              <a
                href="https://jimlegaldocs.s3.eu-central-1.amazonaws.com/userAgreementStandard/JiM_Nutzungsbedingungen_Standard.pdf"
                download
                target="_blank"
                rel="noreferrer"
              >
                Nutzungsbedingungen Standard (PDF)
              </a>
              <a
                href="https://jimlegaldocs.s3.eu-central-1.amazonaws.com/userAgreementPro/JiM_Nutzungsbedingungen_Professional.pdf"
                download
                target="_blank"
                rel="noreferrer"
              >
                Nutzungsbedingungen Professional (PDF)
              </a>
            </p>
          </div>
          <div className="flex items-center justify-center px-16 lg:px-8">
            <Contract />
          </div>
        </div>
      </section>
      {/* <section className="py-20" /> */}
    </AlternativeLayout>
  </>
);

export default UserAgreements;
