import React from 'react';

const Contract = () => (
  <svg width="100%" viewBox="-150 0 1046 721.343">
    <path fill="#f1f1f1" d="M680.602 720.623v-72.34s28.192 51.286 0 72.34z" />
    <path fill="#f1f1f1" d="M682.344 720.61l-53.29-48.92s56.845 13.915 53.29 48.92z" />
    <path
      fill="#fff"
      d="M494.388 319.19L211.602 484.466a4.326 4.326 0 01-5.91-1.55L23.841 171.779a4.326 4.326 0 011.55-5.911L308.177.59a4.326 4.326 0 015.912 1.55l181.849 311.138a4.326 4.326 0 01-1.55 5.911z"
    />
    <path
      fill="#3f3d56"
      d="M494.388 319.19L211.602 484.466a4.326 4.326 0 01-5.91-1.55L23.841 171.779a4.326 4.326 0 011.55-5.911L308.177.59a4.326 4.326 0 015.912 1.55l181.849 311.138a4.326 4.326 0 01-1.55 5.911zM26.264 167.36a2.596 2.596 0 00-.93 3.546l181.85 311.139a2.596 2.596 0 003.546.93l282.785-165.278a2.596 2.596 0 00.93-3.547L312.597 3.012a2.596 2.596 0 00-3.546-.93z"
    />
    <path
      fill="#f2f2f2"
      d="M208.04 198.345l-70.137 40.992a4.326 4.326 0 01-5.911-1.55L91 167.65a4.326 4.326 0 011.55-5.911l70.136-40.992a4.326 4.326 0 015.912 1.55l40.992 70.137a4.326 4.326 0 01-1.55 5.91zM93.422 163.23a2.596 2.596 0 00-.93 3.547l40.992 70.137a2.596 2.596 0 003.547.93l70.137-40.993a2.596 2.596 0 00.93-3.546l-40.993-70.137a2.596 2.596 0 00-3.546-.93z"
    />
    <path
      fill="#991a5f"
      d="M192.91 218.7l-70.138 40.992a3.893 3.893 0 01-5.32-1.395L76.46 188.16a3.893 3.893 0 011.395-5.32l70.137-40.992a3.893 3.893 0 015.32 1.395l40.992 70.137a3.893 3.893 0 01-1.395 5.32z"
    />
    <path
      fill="#ccc"
      d="M362.196 165.804l-219.363 128.21a3.889 3.889 0 01-3.925-6.715l219.364-128.21a3.889 3.889 0 013.924 6.715zM322.74 219.897L156.35 317.145a3.889 3.889 0 01-3.924-6.716l166.388-97.248a3.889 3.889 0 113.924 6.716zM311.736 81.18l-96.998 56.692a3.889 3.889 0 01-3.924-6.715l96.997-56.692a3.889 3.889 0 013.925 6.716zM301.047 118.46l-72.79 42.542a3.889 3.889 0 01-3.925-6.715l72.79-42.543a3.889 3.889 0 013.925 6.715zM389.234 212.064L169.87 340.274a3.889 3.889 0 01-3.925-6.715L385.31 205.35a3.889 3.889 0 013.925 6.715zM349.777 266.157l-166.388 97.248a3.889 3.889 0 11-3.925-6.715l166.388-97.248a3.889 3.889 0 113.925 6.715zM416.271 258.325l-219.363 128.21a3.889 3.889 0 01-3.925-6.715l219.364-128.21a3.889 3.889 0 013.924 6.715zM429.79 281.455l-219.364 128.21a3.889 3.889 0 11-3.924-6.715l219.363-128.21a3.889 3.889 0 113.925 6.715z"
    />
    <path
      fill="#991a5f"
      d="M358.852 371.498a84.703 84.703 0 008.202-11.47c2.161-3.65 4.282-7.55 4.759-11.83a11.683 11.683 0 00-3.499-9.985 8.222 8.222 0 00-9.646-1.097 9.809 9.809 0 00-4.923 8.76 12.959 12.959 0 005.536 9.814c6.484 4.698 16.61 4.452 23.52.877 7.785-4.026 11.806-14.561 5.691-21.773-1.272-1.5-3.389.177-3.07 1.795a8.72 8.72 0 0017.279-2.136l-2.75 1.607a20.99 20.99 0 0020.88-2.24 19.47 19.47 0 003.907-3.776c1.346-1.756 3.606-2.89 5.437-4.155l13.19-9.114c1.94-1.34.096-4.535-1.86-3.183l-11.947 8.254c-1.99 1.375-3.994 2.734-5.973 4.127-1.414.996-2.284 2.603-3.571 3.786a17.288 17.288 0 01-18.252 3.087 1.866 1.866 0 00-2.75 1.607 5.048 5.048 0 01-10.025 1.188l-3.07 1.794c3.945 4.652 1.318 11.787-3.227 14.909-5.083 3.49-12.593 4.097-18.323 1.927a10.81 10.81 0 01-6.365-5.84 6.443 6.443 0 011.462-7.373 4.623 4.623 0 016.427-.069 8.269 8.269 0 012.191 7.46c-.59 3.694-2.633 7.1-4.533 10.256a78.797 78.797 0 01-7.337 10.218c-1.522 1.8 1.103 4.394 2.64 2.575z"
    />
    <path
      fill="#ffb7b7"
      d="M391.593 459.18a8.672 8.672 0 01.872 1.05l40.848-1 4.766-8.707 14.081 5.474-6.938 16.236a6.591 6.591 0 01-6.851 3.953l-46.151-5.575a8.649 8.649 0 11-.627-11.432z"
    />
    <path
      fill="#ffb7b7"
      d="M524.753 707.256L511.322 707.256 504.932 655.449 524.755 655.45 524.753 707.256z"
    />
    <path
      fill="#2f2e41"
      d="M528.178 720.276l-43.308-.002v-.547a16.858 16.858 0 0116.857-16.857l26.452.001z"
    />
    <path
      fill="#ffb7b7"
      d="M402.792 706.958L390.441 701.681 404.916 651.529 423.145 659.317 402.792 706.958z"
    />
    <path
      fill="#2f2e41"
      d="M400.828 720.276l-39.826-17.014.215-.504a16.858 16.858 0 0122.123-8.88l.001.001 24.324 10.392z"
    />
    <path
      fill="#ffb7b7"
      d="M459.468 470.402L458.496 486.141 486.887 489.179 516.593 492.358 507.849 468.459 459.468 470.402z"
    />
    <path
      fill="#2f2e41"
      d="M457.602 486.343l-11.029-76.887 19.117-6.265 5.614-7.7 27.323 1.616 4.012 8.986 14.569 6.884-2.239 41.582 1.633 34.784z"
    />
    <path
      fill="#2f2e41"
      d="M450.66 465.739l-19.444-7.78.083-.346c.083-.349 8.36-34.932 9.458-40.49 1.145-5.79 5.327-7.39 5.505-7.456l.13-.048 7.695 2.343 3.38 26.554zM412.796 697.853l-25.884-5.31 41.37-114.154 14.158-38.936s8.98-31.219 13.038-43.3l2.448-16.21 57.962 6.415s16.189 23.775 11.27 62.526l2.225 145.65-25.884 1.328-21.68-146.896-38.051 80.527s-11.994 34.364-20.575 41.812z"
    />
    <path
      fill="#ffb7b7"
      d="M492.644 491.765a8.673 8.673 0 011.364.075l27.04-30.634-3.145-9.415 13.588-6.604 7.188 16.126a6.591 6.591 0 01-1.759 7.713l-35.467 30.052a8.649 8.649 0 11-8.809-7.313z"
    />
    <path
      fill="#2f2e41"
      d="M518.89 464.187l-11.28-26.533 4.4-18.481 4.74-5.989a2.73 2.73 0 012.193.02c1.748.704 3.237 2.86 4.425 6.41l10.571 30.111zM460.367 385.659v-20.003a26.804 26.804 0 0153.61 0v20.003a3.605 3.605 0 01-3.602 3.6h-46.407a3.605 3.605 0 01-3.6-3.6z"
    />
    <circle cx="481.664" cy="369.142" r="19.652" fill="#ffb7b7" />
    <path
      fill="#2f2e41"
      d="M454.026 367.256a21.227 21.227 0 0121.204-21.203h4a21.227 21.227 0 0121.204 21.203v.4h-8.455l-2.884-8.075-.576 8.075h-4.37l-1.455-4.074-.29 4.074h-28.378z"
    />
    <path
      fill="#2f2e41"
      d="M479.73 391.509a3.547 3.547 0 01-.282-3.766c4.24-8.064 10.175-22.964 2.296-32.153l-.566-.66h22.874v34.341l-20.78 3.666a3.678 3.678 0 01-.637.057 3.586 3.586 0 01-2.905-1.485z"
    />
    <path
      fill="#9f616a"
      d="M212.904 695.94L225.042 700.278 247.551 655.523 229.636 649.121 212.904 695.94z"
    />
    <path
      fill="#2f2e41"
      d="M205.602 706.6l5.622-15.73 23.905 8.544a16.197 16.197 0 019.79 20.679l-.177.495z"
    />
    <path
      fill="#9f616a"
      d="M291.836 707.946L304.726 707.945 310.859 658.226 291.834 658.227 291.836 707.946z"
    />
    <path
      fill="#2f2e41"
      d="M288.548 703.737H313.934a16.178 16.178 0 0116.178 16.177v.526l-41.563.001z"
    />
    <path fill="#f1f1f1" d="M262.459 390.3l5.606-11.077s20.884 5.232 22.782 17.336z" />
    <path
      fill="#2f2e41"
      d="M239.328 516.96L254.573 602.034 214.093 673.622 238.276 683.085 279.282 613.691 284.539 594.765 287.693 693.599 309.503 692.937 319.502 600.65 303.464 514.857 239.328 516.96z"
    />
    <path
      fill="#3f3d56"
      d="M268.267 537.071c-22.396 0-36.616-4.283-36.935-4.351l-.515-.11 11.665-54.086.525-49.89c0-21.14 13.915-36.727 15.846-38.166a9.096 9.096 0 014.837-5.945c4.642-2.106 10.313.421 10.552.53l17.023 6.383.08.236c.205.605 22.628 74.214 22.628 74.214s-1.358 64.315-1.83 66.422c0 0-31.432 4.763-43.876 4.763z"
    />
    <circle cx="285.477" cy="352.727" r="23.008" fill="#9f616a" />
    <path d="M259.823 432.715L300.076 488.877 306.02 462.65 259.823 432.715z" opacity="0.2" />
    <path
      fill="#9f616a"
      d="M386.024 470.928a9.874 9.874 0 01-14.825-3.073l-34.647 5.546 7.506-16.617 31.75-2.719a9.927 9.927 0 0110.216 16.863z"
    />
    <path
      fill="#f1f1f1"
      d="M345.842 455.838L358.291 454.617 361.741 471.983 346.076 472.26 345.842 455.838z"
    />
    <path
      fill="#3f3d56"
      d="M356.047 478.034l-60.97-5.429-30.786-48.96-.094-.433a16.855 16.855 0 0131.466-11.286l18.072 35.25 36.473 5.514z"
    />
    <path
      fill="#2f2e41"
      d="M265.879 372.703l19.097.686c1.164.042 4.323-12.521 4.737-15.127a7.113 7.113 0 017.42-5.754c1.429.135 5.034-2.536 8.753-5.698 7.062-6.004 6.695-17.28-1.063-22.354q-.318-.208-.624-.38c-4.894-2.739-10.619-3.658-16.227-3.729-5.083-.063-10.311.577-14.79 2.983-8.03 4.314-12.302 13.74-12.678 22.848s2.545 18.012 5.858 26.503"
    />
    <path
      fill="#cbcbcb"
      d="M744.602 721.343H1a1 1 0 010-2h744a1 1 0 011 1c0 .552-.845 1-1.398 1z"
    />
  </svg>
);

export default Contract;

/*
<svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" width="746" height="721.34323" viewBox="0 0 746 721.34323" xmlns:xlink="http://www.w3.org/1999/xlink">
  <path d="M907.60236,809.95184v-72.34S935.794,788.89781,907.60236,809.95184Z" transform="translate(-227 -89.32838)" fill="#f1f1f1" />
  <path d="M909.34383,809.93912l-53.28962-48.92125S912.8994,774.9334,909.34383,809.93912Z" transform="translate(-227 -89.32838)" fill="#f1f1f1" />
  <path d="M721.38766,408.51766,438.60244,573.79556a4.32611,4.32611,0,0,1-5.91113-1.55023L250.842,261.107a4.32609,4.32609,0,0,1,1.55022-5.91112L535.17747,89.918a4.3261,4.3261,0,0,1,5.91112,1.55023l181.8493,311.13836A4.3261,4.3261,0,0,1,721.38766,408.51766Z" transform="translate(-227 -89.32838)" fill="#fff" />
  <path d="M721.38766,408.51766,438.60244,573.79556a4.32611,4.32611,0,0,1-5.91113-1.55023L250.842,261.107a4.32609,4.32609,0,0,1,1.55022-5.91112L535.17747,89.918a4.3261,4.3261,0,0,1,5.91112,1.55023l181.8493,311.13836A4.3261,4.3261,0,0,1,721.38766,408.51766ZM253.26442,256.68812a2.59553,2.59553,0,0,0-.93013,3.54668L434.18358,571.37315a2.59553,2.59553,0,0,0,3.54668.93014l282.78522-165.2779a2.59552,2.59552,0,0,0,.93014-3.54667L539.59632,92.34036a2.59553,2.59553,0,0,0-3.54668-.93014Z" transform="translate(-227 -89.32838)" fill="#3f3d56" />
  <path d="M435.03979,287.67306l-70.1367,40.99241a4.32609,4.32609,0,0,1-5.91112-1.55023l-40.99241-70.1367a4.32608,4.32608,0,0,1,1.55023-5.91112l70.1367-40.99241a4.32609,4.32609,0,0,1,5.91112,1.55023L436.59,281.76194A4.32608,4.32608,0,0,1,435.03979,287.67306ZM320.422,252.55969a2.59552,2.59552,0,0,0-.93014,3.54667l40.99241,70.1367a2.59552,2.59552,0,0,0,3.54667.93014l70.1367-40.99241a2.59552,2.59552,0,0,0,.93014-3.54667l-40.99241-70.1367a2.59552,2.59552,0,0,0-3.54667-.93014Z" transform="translate(-227 -89.32838)" fill="#f2f2f2" />
  <path d="M419.90917,308.028l-70.1367,40.99241a3.89341,3.89341,0,0,1-5.32-1.39521l-40.99241-70.1367a3.89342,3.89342,0,0,1,1.39521-5.32l70.1367-40.99241a3.89342,3.89342,0,0,1,5.32,1.39521l40.99241,70.1367A3.89342,3.89342,0,0,1,419.90917,308.028Z" transform="translate(-227 -89.32838)" fill="#991a5f" />
  <path d="M589.19632,255.13236,369.83258,383.34266a3.889,3.889,0,0,1-3.9248-6.71522l219.36373-128.2103a3.889,3.889,0,0,1,3.92481,6.71522Z" transform="translate(-227 -89.32838)" fill="#ccc" />
  <path d="M549.73949,309.2249l-166.38813,97.248a3.889,3.889,0,0,1-3.92481-6.71522l166.38814-97.24795a3.889,3.889,0,1,1,3.9248,6.71522Z" transform="translate(-227 -89.32838)" fill="#ccc" />
  <path d="M538.736,170.509l-96.99757,56.69163a3.889,3.889,0,0,1-3.92481-6.71521l96.99757-56.69163A3.889,3.889,0,0,1,538.736,170.509Z" transform="translate(-227 -89.32838)" fill="#ccc" />
  <path d="M528.04745,207.78755l-72.79026,42.54331a3.889,3.889,0,0,1-3.92481-6.71522l72.79026-42.54331a3.889,3.889,0,0,1,3.92481,6.71522Z" transform="translate(-227 -89.32838)" fill="#ccc" />
  <path d="M616.23386,301.39274,396.87013,429.603a3.889,3.889,0,0,1-3.92481-6.71522l219.36374-128.2103a3.889,3.889,0,0,1,3.9248,6.71522Z" transform="translate(-227 -89.32838)" fill="#ccc" />
  <path d="M576.777,355.48528l-166.38814,97.248a3.889,3.889,0,1,1-3.9248-6.71522l166.38813-97.24795a3.889,3.889,0,1,1,3.92481,6.71522Z" transform="translate(-227 -89.32838)" fill="#ccc" />
  <path d="M643.27141,347.65312,423.90767,475.86342a3.889,3.889,0,0,1-3.9248-6.71522L639.3466,340.9379a3.889,3.889,0,0,1,3.92481,6.71522Z" transform="translate(-227 -89.32838)" fill="#ccc" />
  <path d="M656.79018,370.78331,437.42645,498.9936a3.889,3.889,0,1,1-3.92481-6.71521l219.36374-128.2103a3.889,3.889,0,1,1,3.9248,6.71522Z" transform="translate(-227 -89.32838)" fill="#ccc" />
  <path d="M585.85233,460.8263a84.70308,84.70308,0,0,0,8.2014-11.47053c2.16171-3.64878,4.2824-7.55,4.759-11.82932a11.68306,11.68306,0,0,0-3.49862-9.98546,8.22181,8.22181,0,0,0-9.64609-1.09646,9.80894,9.80894,0,0,0-4.923,8.75985,12.959,12.959,0,0,0,5.53588,9.81385c6.48388,4.69765,16.60994,4.4522,23.52077.87731,7.784-4.02657,11.80578-14.56146,5.69036-21.77281-1.272-1.4999-3.38871.17627-3.07005,1.79433a8.72086,8.72086,0,0,0,17.279-2.13545l-2.7491,1.60676a20.99018,20.99018,0,0,0,20.87922-2.23986,19.4701,19.4701,0,0,0,3.90715-3.77622c1.34569-1.75547,3.60614-2.88981,5.43647-4.15451l13.19043-9.11411c1.94021-1.34062.09538-4.53543-1.86081-3.18378l-11.946,8.25429c-1.99075,1.37553-3.99447,2.73415-5.973,4.12714-1.4144.9958-2.28466,2.60338-3.57157,3.78591a17.28811,17.28811,0,0,1-18.25191,3.08747,1.86585,1.86585,0,0,0-2.7491,1.60675,5.0482,5.0482,0,0,1-10.02595,1.18765l-3.07006,1.79434c3.94506,4.652,1.31808,11.78725-3.22684,14.90874-5.08283,3.49093-12.59333,4.09689-18.32272,1.92692a10.81086,10.81086,0,0,1-6.365-5.84005,6.44305,6.44305,0,0,1,1.462-7.373,4.62265,4.62265,0,0,1,6.427-.06854,8.269,8.269,0,0,1,2.19083,7.45928c-.5909,3.69454-2.63337,7.10141-4.53329,10.257a78.797,78.797,0,0,1-7.337,10.21716c-1.52123,1.80027,1.10343,4.39464,2.64071,2.57538Z" transform="translate(-227 -89.32838)" fill="#991a5f" />
  <path d="M618.59271,548.50763a8.67233,8.67233,0,0,1,.872,1.051l40.84857-.99946,4.76541-8.70792,14.08178,5.47419-6.93809,16.23551a6.59134,6.59134,0,0,1-6.8516,3.95362l-46.15126-5.57517a8.64875,8.64875,0,1,1-.62683-11.43176Z" transform="translate(-227 -89.32838)" fill="#ffb7b7" />
  <polygon points="524.753 707.256 511.322 707.256 504.932 655.449 524.755 655.45 524.753 707.256" fill="#ffb7b7" />
  <path d="M755.17806,809.60436l-43.308-.00161V809.055a16.85758,16.85758,0,0,1,16.85668-16.8564h.00107l26.451.00107Z" transform="translate(-227 -89.32838)" fill="#2f2e41" />
  <polygon points="402.792 706.958 390.441 701.681 404.916 651.529 423.145 659.317 402.792 706.958" fill="#ffb7b7" />
  <path d="M627.82776,809.60436l-39.826-17.014.21518-.50375a16.85759,16.85759,0,0,1,22.12325-8.87963l.001.00042,24.3243,10.39164Z" transform="translate(-227 -89.32838)" fill="#2f2e41" />
  <polygon points="459.468 470.402 458.496 486.141 486.887 489.179 516.593 492.358 507.849 468.459 459.468 470.402" fill="#ffb7b7" />
  <path d="M684.60236,575.67162l-11.02912-76.88748,19.11648-6.26508,5.61475-7.7001,27.32289,1.61686,4.01137,8.98546,14.56924,6.88423-2.239,41.58174,1.63341,34.78437Z" transform="translate(-227 -89.32838)" fill="#2f2e41" />
  <path d="M677.66064,555.06728l-19.44456-7.77975.08288-.346c.08348-.3488,8.35927-34.93247,9.45817-40.48989,1.14475-5.79,5.3269-7.39073,5.50452-7.4559l.12974-.04788,7.69528,2.34222,3.37955,26.55448Z" transform="translate(-227 -89.32838)" fill="#2f2e41" />
  <path d="M639.79576,787.18106l-25.88371-5.30948,41.36969-114.1538,14.15862-38.93618s8.9802-31.21887,13.03747-43.30013l2.44851-16.21028,57.96181,6.41562s16.18859,23.77448,11.27061,62.52545l2.22432,145.65037L730.49937,785.19,708.819,638.29442l-38.05126,80.5271s-11.99335,34.36378-20.57424,41.81214Z" transform="translate(-227 -89.32838)" fill="#2f2e41" />
  <path d="M719.64415,581.093a8.67278,8.67278,0,0,1,1.36357.07512L748.0481,550.5344l-3.14545-9.415,13.58853-6.60419,7.18819,16.12634a6.59137,6.59137,0,0,1-1.75929,7.71237l-35.46688,30.052a8.64875,8.64875,0,1,1-8.80905-7.31289Z" transform="translate(-227 -89.32838)" fill="#ffb7b7" />
  <path d="M745.8896,553.5156l-11.2798-26.53276,4.39939-18.48144,4.74015-5.9887a2.73032,2.73032,0,0,1,2.19377.02051c1.74821.70363,3.23693,2.86,4.42494,6.40951l10.57114,30.11085Z" transform="translate(-227 -89.32838)" fill="#2f2e41" />
  <path d="M687.36746,474.98733V454.98412a26.8043,26.8043,0,0,1,53.6086,0v20.00321a3.60464,3.60464,0,0,1-3.60058,3.60058H690.968A3.60463,3.60463,0,0,1,687.36746,474.98733Z" transform="translate(-227 -89.32838)" fill="#2f2e41" />
  <circle cx="481.66375" cy="369.1419" r="19.65197" fill="#ffb7b7" />
  <path d="M681.0262,456.58438a21.22738,21.22738,0,0,1,21.20341-21.2034h4.00083a21.22721,21.22721,0,0,1,21.20321,21.2034v.40006H718.979l-2.88366-8.07473-.57666,8.07473H711.1492l-1.45492-4.07409-.29106,4.07409h-28.377Z" transform="translate(-227 -89.32838)" fill="#2f2e41" />
  <path d="M706.72972,480.8373a3.54728,3.54728,0,0,1-.28188-3.76623c4.23955-8.06379,10.17546-22.96384,2.29646-32.15282l-.5663-.66026h22.87359v34.34145l-20.77892,3.66621a3.67755,3.67755,0,0,1-.63721.05626A3.58611,3.58611,0,0,1,706.72972,480.8373Z" transform="translate(-227 -89.32838)" fill="#2f2e41" />
  <polygon points="212.904 695.94 225.042 700.278 247.551 655.523 229.636 649.121 212.904 695.94" fill="#9f616a" />
  <path d="M432.60236,795.92854l5.62164-15.7298,23.90478,8.54334a16.197,16.197,0,0,1,9.79011,20.67889l-.17712.49491Z" transform="translate(-227 -89.32838)" fill="#2f2e41" />
  <polygon points="291.836 707.946 304.726 707.945 310.859 658.226 291.834 658.227 291.836 707.946" fill="#9f616a" />
  <path d="M515.54791,793.06587l25.3854-.001h.001a16.1777,16.1777,0,0,1,16.178,16.17747v.52571l-41.56364.00154Z" transform="translate(-227 -89.32838)" fill="#2f2e41" />
  <path d="M489.45887,479.62865l5.60594-11.07722s20.884,5.23229,22.78254,17.3361Z" transform="translate(-227 -89.32838)" fill="#f1f1f1" />
  <polygon points="239.328 516.96 254.573 602.034 214.093 673.622 238.276 683.085 279.282 613.691 284.539 594.765 287.693 693.599 309.503 692.937 319.502 600.65 303.464 514.857 239.328 516.96" fill="#2f2e41" />
  <path d="M495.26738,626.39974c-22.39644,0-36.61635-4.28374-36.93491-4.35151l-.5157-.10986L469.4823,567.8526l.5252-49.88931c0-21.14145,13.91444-36.728,15.84531-38.16653a9.09551,9.09551,0,0,1,4.83743-5.94508c4.64131-2.10593,10.313.421,10.55173.52982l17.02328,6.38248.08009.23616c.2051.60478,22.62721,74.21379,22.62721,74.21379s-1.35707,64.315-1.83016,66.422C539.14239,621.6359,507.71147,626.39974,495.26738,626.39974Z" transform="translate(-227 -89.32838)" fill="#3f3d56" />
  <circle cx="285.4768" cy="352.72704" r="23.00821" fill="#9f616a" />
  <polygon points="259.823 432.715 300.076 488.877 306.02 462.65 259.823 432.715" opacity="0.2" />
  <path d="M613.02352,560.25673a9.87366,9.87366,0,0,1-14.82487-3.07339l-34.64668,5.5456,7.506-16.61679,31.75033-2.71866a9.92718,9.92718,0,0,1,10.21526,16.86324Z" transform="translate(-227 -89.32838)" fill="#9f616a" />
  <polygon points="345.842 455.838 358.291 454.617 361.741 471.983 346.076 472.26 345.842 455.838" fill="#f1f1f1" />
  <path d="M583.04706,567.36242l-60.96976-5.42884L491.29145,512.973l-.09483-.4327a16.85541,16.85541,0,0,1,31.4666-11.28612l18.072,35.25105,36.47268,5.51312Z" transform="translate(-227 -89.32838)" fill="#3f3d56" />
  <path d="M492.87889,462.03183l19.09695.68547c1.16406.04178,4.32334-12.521,4.7369-15.12708a7.11274,7.11274,0,0,1,7.41971-5.754c1.42953.135,5.03429-2.53568,8.754-5.69809,7.062-6.00388,6.69461-17.28005-1.063-22.35333q-.318-.208-.62428-.37983c-4.89391-2.73917-10.61888-3.659-16.22678-3.72917-5.08371-.06358-10.31154.57628-14.79018,2.98249-8.02926,4.31383-12.3024,13.74076-12.67776,22.84774s2.5442,18.012,5.85721,26.50324" transform="translate(-227 -89.32838)" fill="#2f2e41" />
  <path d="M971.60236,810.67162H228a1,1,0,0,1,0-2H972a1,1,0,0,1,1,1C973,810.22386,972.15461,810.67162,971.60236,810.67162Z" transform="translate(-227 -89.32838)" fill="#cbcbcb" />
</svg>
*/
